import Template from '../Template'
import * as style from './style.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageAbout = () => {
  return (
    <Template>
      <div className={style.pageWrapper}>
        <Helmet>
          <title>About | doggos.com</title>
        </Helmet>
        <div className={style.heroContainer}>
          <h1>About us</h1>
          <p className={style.subline}>
            Doggos.com is the fastest-growing platform for finding a dog sitter
            near you
          </p>
        </div>
        <div className={style.image}>
          <div className={style.copy}>
            <h2>How it started</h2>
            <p>
              We have been publishing <strong>FREE</strong> educational
              resources for dog owners since 2017 (you may have read some of our
              articles! 🎉 🦮)
            </p>
          </div>
          <div>
            <StaticImage
              src={'./1.jpg'}
              alt='Image of a black German Shepherd'
              className={style.image}
            />
          </div>
        </div>
        <div className={style.content}>
          <h2>Today</h2>
          <p>
            Since then, our small team saw an opportunity to provide more
            services and features for dog owners and added the ability to chat
            and book online with local businesses to the platform in a limited
            number of locations.
          </p>
          <p>
            We've been hard at work ever since building new features and working
            with our partners to tailor our solution to the real needs pet
            owners and businesses face today, starting with the high cost of pet
            software, and the difficulty of use once you get past the "sales
            pitch."
          </p>

          <h3>Contact us</h3>

          <p>
            Questions about this website or any of its material? Please reach
            out{' '}
            <a
              title='Email support@doggos.com'
              href='mailto:support@doggos.com'
            >
              via email
            </a>{' '}
            anytime and someone will get back to you as soon as possible.
          </p>
          <p className={style.note}>
            This website participates in the Amazon affiliates program, meaning
            we may earn money when you click certain links.
          </p>
        </div>
      </div>
    </Template>
  )
}

export default PageAbout
